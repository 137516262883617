var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCardBody",
    [
      _c("alert-section", {
        attrs: {
          successAlertMessage: _vm.successAlertMessage,
          dismissSecs: _vm.dismissSecs,
          dismissSuccessAlert: _vm.dismissSuccessAlert,
          errorAlertMessage: _vm.errorAlertMessage,
          showErrorAlert: _vm.showErrorAlert,
        },
      }),
      _vm.loadedItems.length
        ? _c(
            "div",
            [
              _c("CDataTable", {
                attrs: {
                  striped: "",
                  hover: "",
                  items: _vm.loadedItems,
                  fields: _vm.fields,
                  sorter: { external: true, resetable: true },
                  "column-filter": { external: true, lazy: true },
                  itemsPerPageSelect: {
                    external: true,
                    values: [5, 15, 25, 50, 100, 150, 200, 250, 500],
                  },
                  "items-per-page": _vm.itemsPerPage,
                  loading: _vm.loading,
                  noItemsView: {
                    noResults: "No filtering results are available!",
                    noItems: "No bank transaction found!",
                  },
                },
                on: {
                  "update:itemsPerPage": function ($event) {
                    _vm.itemsPerPage = $event
                  },
                  "update:items-per-page": function ($event) {
                    _vm.itemsPerPage = $event
                  },
                  "update:sorter-value": _vm.sorterValue,
                  "pagination-change": _vm.paginationChange,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "id",
                      fn: function ({ item }) {
                        return [
                          _c("td", [_vm._v(" #" + _vm._s(item.id) + " ")]),
                        ]
                      },
                    },
                    {
                      key: "type",
                      fn: function ({ item }) {
                        return [
                          _c("td", [
                            _c("em", [
                              _vm._v(_vm._s(_vm._f("ucfirst")(item.type))),
                            ]),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "txn_cnt",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "td",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "CLink",
                                {
                                  staticClass: "d-inline count bg-info",
                                  attrs: {
                                    to: {
                                      name: _vm.getInvoiceRouteName(item),
                                      params: { txn_id: item.id },
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.txn_count) + " ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "amount",
                      fn: function ({ item }) {
                        return [
                          _c("td", { staticClass: "text-center" }, [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toCurrency")(
                                    item.total_paid,
                                    "remove_thousand_separator"
                                  )
                                )
                              ),
                            ]),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "date-filter",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "input-group mb-1" }, [
                            _c("div", { staticClass: "input-group-prepend" }, [
                              _c(
                                "span",
                                { staticClass: "input-group-text p-1 w-min" },
                                [_vm._v("From")]
                              ),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filters.txn.startDate,
                                  expression: "filters.txn.startDate",
                                },
                              ],
                              staticClass: "w-min",
                              attrs: { id: "date-filter-from", type: "date" },
                              domProps: { value: _vm.filters.txn.startDate },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.filters.txn,
                                    "startDate",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "input-group" }, [
                            _c("div", { staticClass: "input-group-prepend" }, [
                              _c(
                                "span",
                                { staticClass: "input-group-text p-1 w-min" },
                                [_vm._v("To")]
                              ),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filters.txn.endDate,
                                  expression: "filters.txn.endDate",
                                },
                              ],
                              staticClass: "w-min",
                              attrs: { id: "date-filter-from", type: "date" },
                              domProps: { value: _vm.filters.txn.endDate },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.filters.txn,
                                    "endDate",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "date",
                      fn: function ({ item }) {
                        return [
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  new Date(item.created_at).toLocaleString()
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "file",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "td",
                            [
                              _c(
                                "CLink",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.downloadTxnFile(item.file_name)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item.file_name))]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "actions",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "td",
                            { staticClass: "py-2 text-center" },
                            [
                              _c(
                                "CButton",
                                {
                                  directives: [
                                    {
                                      name: "c-tooltip",
                                      rawName: "v-c-tooltip",
                                      value: {
                                        html: true,
                                        content: "Invoices",
                                      },
                                      expression:
                                        "{ html: true, content: 'Invoices' }",
                                    },
                                  ],
                                  attrs: {
                                    size: "sm",
                                    color: "info",
                                    shape: "pill",
                                    to: {
                                      name: _vm.getInvoiceRouteName(item),
                                      params: { txn_id: item.id },
                                    },
                                  },
                                },
                                [
                                  _c("CIcon", {
                                    attrs: { name: "cil-spreadsheet" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "CButton",
                                {
                                  directives: [
                                    {
                                      name: "c-tooltip",
                                      rawName: "v-c-tooltip",
                                      value: {
                                        html: true,
                                        content: "Download",
                                      },
                                      expression:
                                        "{ html: true, content: 'Download' }",
                                    },
                                  ],
                                  staticClass: "ml-1",
                                  attrs: {
                                    size: "sm",
                                    color: "primary",
                                    shape: "pill",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.downloadTxnFile(item.file_name)
                                    },
                                  },
                                },
                                [
                                  _c("CIcon", {
                                    attrs: { name: "cil-cloud-download" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1090474989
                ),
              }),
              _c(
                "CCard",
                { staticClass: "actions sticky-bottom" },
                [
                  _c("CCardBody", { staticClass: "p-2" }, [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex flex-wrap align-items-center",
                        staticStyle: { gap: "0.75rem" },
                      },
                      [
                        _c("div", [
                          _c("h5", { staticClass: "mt-1" }, [
                            _vm._v("Total: "),
                            _c(
                              "span",
                              { staticClass: "d-inline count bg-primary pb-1" },
                              [_vm._v(_vm._s(_vm.total))]
                            ),
                          ]),
                        ]),
                        _vm.pages > 1
                          ? _c(
                              "div",
                              { staticClass: "ml-auto" },
                              [
                                _c("CPagination", {
                                  attrs: {
                                    align: "center",
                                    dots: false,
                                    pages: _vm.pages,
                                    "active-page": _vm.activePage,
                                  },
                                  on: {
                                    "update:activePage": function ($event) {
                                      _vm.activePage = $event
                                    },
                                    "update:active-page": function ($event) {
                                      _vm.activePage = $event
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _vm.loading
                ? _c("CSpinner", { attrs: { color: "primary" } })
                : _c("h6", [_vm._v("No bank transaction found!")]),
            ],
            1
          ),
      _c("mc-spinner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.submitted,
            expression: "submitted",
          },
        ],
        attrs: { opacity: 0.8, mtop: 32 + _vm.mcSpinnerMarginTop + "px" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }